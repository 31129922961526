/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function ($) {

    window.Gwrf = window.GPReloadForm = function (args) {
        var self = this;
        self.formId = args.formId;
        self.spinnerUrl = args.spinnerUrl;
        self.refreshTime = args.refreshTime;
        self.refreshTimeout = null;

        if (window['gwrf_' + args.formId]) {
            return window['gwrf_' + args.formId];
        }

        self.formWrapper = $('#gform_wrapper_' + self.formId);
        self.staticElem = self.formWrapper.parent();

        var clonedElem = $('<div>').append(self.formWrapper.clone());
        clonedElem.find('.ginput_counter').remove();

        self.formHtml = clonedElem.html();
        self.spinnerInitialized = false;

        if (self.staticElem.data('gwrf')) {
            return self.staticElem.data('gwrf');
        }
        self.init = function () {
            $(document).bind('gform_confirmation_loaded', function (event, formId) {
                if (formId !== self.formId || self.refreshTime <= 0 || self.staticElem.find('.form_saved_message').length > 0) {
                    return;
                }
                self.refreshTimeout = setTimeout(function () {
                    self.reloadForm();
                }, self.refreshTime * 1000);
            });
            self.staticElem.on('click', 'a.gws-reload-form', function (event) {
                event.preventDefault();
                self.reloadForm();
            });
            self.staticElem.data('gwrf', self);
        };
        self.reloadForm = function () {
            if (self.refreshTimeout) {
                clearTimeout(self.refreshTimeout);
            }
            self.staticElem.find('.gform_confirmation_message_' + self.formId + ', #gform_wrapper_' + self.formId).replaceWith(self.formHtml);
            window['gf_submitting_' + self.formId] = false;
            gformInitSpinner(self.formId, self.spinnerUrl);
            $(document).trigger('gform_post_render', [self.formId, 0]);
            if (window.gformInitDatepicker) {
                gformInitDatepicker();
            }

        };
        self.init();
    };

})(jQuery);

var dfile, donline;

(function ($) {

    function center_map(map) {
        var bounds = new google.maps.LatLngBounds();
        $.each(map.markers, function (i, marker) {
            var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
            bounds.extend(latlng);
        });
        if (map.markers.length === 1) {
            map.setCenter(bounds.getCenter());
            map.setZoom(16);
        } else {
            map.fitBounds(bounds);
        }
    }

    function add_marker($marker, map) {
        var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));
        var marker = new google.maps.Marker({
            position: latlng,
            map: map
        });
        map.markers.push(marker);
        if ($marker.html()) {
            var infowindow = new google.maps.InfoWindow({
                content: $marker.html()
            });
            google.maps.event.addListener(marker, 'click', function () {
                infowindow.open(map, marker);
            });
        }
    }

    function new_map($el) {
        var $markers = $el.find('.marker');
        var args = {
            zoom: 16,
            center: new google.maps.LatLng(0, 0),
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        var map = new google.maps.Map($el[0], args);
        map.markers = [];
        $markers.each(function () {
            add_marker($(this), map);
        });
        center_map(map);
        return map;
    }

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                $('a[href^="#"]').on('click', function (e) {
                    var target = this.hash,
                        noscroll = $(this).data('noscroll');
                    $target = $(target);

                    if ($target.length > 0 && noscroll !== 1) {
                        e.preventDefault();
                        $('html, body').stop().animate({
                            'scrollTop': $target.offset().top
                        }, 900, 'swing', function () {
                            window.location.hash = target;
                        });
                    }
                });
                $('.menu-trigger').on('click', function () {
                    $('.nav-primary.mobile').toggle();
                });
                $('.nav-primary.mobile .menu-item-has-children > a').on('click', function (e) {
                    e.preventDefault();
                    $(this).parent().toggleClass('show').find('> ul').slideToggle();
                });
                $('.search-trigger').on('click', function (e) {
                    e.preventDefault();
                    $('.search-container').toggle("slow");
                });
                $('.faq .question').on('click', function () {
                    $(this).parent().toggleClass('active');
                    $(this).parent().find('.answer').slideToggle();
                });

                $('.products-tabs').responsiveTabs({
                    startCollapsed: 'accordion',
                });

                $("#input_2_6, #input_4_6").chosen({
                    no_results_text: "No results found",
                    disable_search_threshold: 10
                });
                $("select#country").chosen({
                    no_results_text: "No results found",
                    disable_search_threshold: 10,
                    placeholder_text_single: '--- Select country ---'
                });
                $("select#type").chosen({
                    no_results_text: "No results found",
                    disable_search_threshold: 10,
                    placeholder_text_single: '--- Select product type ---'
                });
                $.validator.setDefaults({ignore: ":hidden:not(select)"});
                $("#find-distributor").validate({
                    rules: {name: "required", type: "required"},
                    message: {name: "Select a Country", type: "Select a Type"},
                    submitHandler: function (form) {
                        $.ajax({
                            type: 'post',
                            url: sprint.ajaxurl,
                            data: {
                                'action': 'find-distributor',
                                'country': $('#country option:selected').text(),
                                'type': $('#type option:selected').text()
                            },
                            success: function (response) {
                                $('.find-distributor__result').html(response.content);
                                $("html,body").animate({scrollTop: $("#find-distributor__result").offset().top  - 200}, "slow");
                            }
                        });
                    }
                });
                $('.product-selector__form').validate({
                    errorPlacement: function (error, element) {
                        if (element.parent('.input-group').length) {
                            error.insertAfter(element.parent());
                        } else if (element.hasClass('custom-select')) {
                            error.insertAfter(element.next('span'));
                        } else {
                            error.insertAfter(element);
                        }
                    }
                });
                $(document).bind('gform_confirmation_loaded', function (event, formId) {

                    var callback = function () {
                        if (typeof (url) !== 'undefined') {
                            window.location = url;
                        }
                    };

                    if (formId === 4) {
                        //$('#pum-571').popmake('close');
                        document.cookie = "download_form_completed=1; expires=Thu, 1 Jan 2500 12:00:00 UTC";
                        // document.location.href='/downloads/?file=' + dfile + '&online=' + donline;
                        window.open('/downloads/?file=' + dfile + '&online=' + donline, '_blank');

                        gtag('event', 'conversion', {
                            'send_to': 'AW-1034832649/uLprCNTx9n0QiZa57QM',
                            'event_callback': callback
                        });
                    } else if (formId === 2) {
                        gtag('event', 'conversion', {
                            'send_to': 'AW-1034832649/PoCoCLOA830QiZa57QM',
                            'event_callback': callback
                        });
                    }

                });
                $('.request-download-modal-trigger').on('click', function (e) {
                    e.preventDefault();
                    dfile = $(this).data('file');
                    donline = $(this).data('online');

                    if (document.cookie.indexOf("pum-571=") >= 0) {
                        // document.location.href='/downloads/?file=' + dfile + '&online=' + donline;
                        window.open('/downloads/?file=' + dfile + '&online=' + donline, '_blank');
                    } else {
                        $('#pum-571').popmake('open');
                    }


                });
                $('.helper').on('click', function () {
                    $(this).toggleClass('active');
                });

                $('.helper .close-btn').on('click', function (e) {
                    e.stopPropagation();
                    var elem = $('.product-selector__form').find('.helper.active');
                    elem.removeClass('active');
                });
            },
            finalize: function () {
                var map = null;
                $(document).ready(function () {
                    $('.acf-map').each(function () {
                        map = new_map($(this));
                    });
                });
                window.gwrf_4 = new Gwrf({
                    "formId": 4,
                    "spinnerUrl": "",
                    "refreshTime": 0
                });
                $('#pum-571').on('pumAfterClose', function () {
                    var gwrf = window.gwrf_4;
                    if (typeof gwrf !== 'undefined') {
                        gwrf.reloadForm();
                    }
                });
                $('[data-toggle="tooltip"]').tooltip();
                $('.custom-select').select2({
                    width: '100%'
                });
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
                var bv = new Bideo(),
                    video = $('#background_video');

                bv.init({
                    // Video element
                    videoEl: document.querySelector('#background_video'),

                    // Container element
                    container: document.querySelector('.homepage-hero'),

                    // Resize
                    resize: true,

                    // autoplay: false,

                    isMobile: window.matchMedia('(max-width: 768px)').matches,

                    playButton: document.querySelector('#play'),
                    pauseButton: document.querySelector('#pause'),

                    // Array of objects containing the src and type
                    // of different video formats to add
                    src: [
                        {
                            src: video.data('mp4'),
                            type: 'video/mp4'
                        },
                        {
                            src: video.data('webm'),
                            type: 'video/webm;codecs="vp8, vorbis"'
                        }
                    ],

                    // What to do once video loads (initial frame)
                    onLoad: function () {
                        document.querySelector('#video_cover').style.display = 'none';
                    }
                });
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

    // Header height
    function header_height() {
        var header_height = $('.sp-header').height();
        $('body').css('padding-top', header_height);
    }

    header_height();

    $(window).on('resize', header_height);

    // Submit GForm
    jQuery(document).on('gform_confirmation_loaded', function(event, formId){
        if ( formId === 2 ) {
            $('.gform-title').text('Thanks for requesting the pricing');
        } else if ( formId === 4 ) {
            $('body').find('.gform_confirmation_wrapper').prepend('<h2>Thank-you for your details</h2>');
        }
    });

})(jQuery); // Fully reference jQuery after this point.
